<template>
	<Modal
		:title="title"
		:value="visible"
		width="400"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="org-funds-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="teamForm"
			style="margin-bottom: 30px"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="被授权人名称" prop="userId">
						<Select
							v-model="formData.userId"
							filterable
							clearable
							placeholder="请选择被授权人名称"
							@on-change="onUserIdChange"
							:disabled="viewsData !== null"
						>
							<Option
								:value="user.id"
								v-for="user in authUsers"
								:key="user.id"
								>{{ user.realName }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="被授权人账号" prop="username">
						<Input
							v-model="formData.username"
							placeholder="请填写被授权人账号"
							disabled
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="被授权角色" prop="roleId">
						<Select
							v-model="formData.roleId"
							:transfer="true"
							placeholder="请选择角色"
							clearable
							:disabled="viewsData !== null"
						>
							<Option
								v-for="(opt, i) in roleList"
								:key="`${opt.id}_${i}`"
								:value="opt.id"
								:label="opt.name"
							></Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
			<!--			<Row :gutter="32">-->
			<!--        <Col span="24">用户账号与名称不一致，请检查该用户是否注册</Col>-->
			<!--      </Row>-->
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="授权原因" prop="reason">
						<Input
							type="textarea"
							:row="3"
							v-model.trim="formData.reason"
							placeholder="请输入授权原因"
							:disabled="viewsData !== null"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onSubmit">确定</Button>
		</div>
	</Modal>
</template>

<script>
import publicApi from "@/api/public"
import processApi from "@/api/setting/process"
import teamApi from "@/api/setting/team"
const { publicGetuserList } = publicApi
const { apiGetRoleList } = processApi
const { addAuthRecord } = teamApi

export default {
	name: "Add",
	props: {
		visible: {},
		viewsData: {}
	},
	data() {
		return {
			loading: false,
			authUsers: [],
			roleList: [],
			formData: {
				userId: null,
				username: null,
				roleId: null,
				reason: null
			},
			rules: {
				userId: {
					required: true,
					message: "被授权人不能为空",
					trigger: "blur change"
				},
				username: {
					required: true,
					message: "被授权人账号不能为空",
					trigger: "blur"
				},
				roleId: {
					required: true,
					message: "被授权角色不能为空",
					trigger: "blur"
				},
				reason: {
					required: false,
					message: "授权原因不能为空",
					trigger: "blur"
				}
			},
			title: ""
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.getuserList()
				this.getRoleList()
				this.$refs.teamForm && this.$refs.teamForm.resetFields()
				if (this.viewsData !== null) {
					this.formData = {
						userId: null,
						username: null,
						roleId: null,
						reason: null,
						...this.viewsData
					}
					this.title = "查看"
				} else {
					this.formData = {
						userId: null,
						username: null,
						roleId: null,
						reason: null
					}
					this.title = "新增"
				}
			}
		}
	},
	methods: {
		/**
		 * 获取用户列表
		 */
		getuserList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetuserList()
				if (res) {
					this.authUsers = res.data || []
				}
				this.loading = false
			})
		},
		/**
		 * 获取角色列表
		 */
		getRoleList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetRoleList()
				if (res) {
					this.roleList = res.data || []
				}
				this.loading = false
			})
		},
		// 匹配用户账号
		onUserIdChange(e) {
			const obj = this.authUsers.find(o => o.id === e)
			this.formData.username = obj.username
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onSubmit() {
			this.$refs.teamForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await addAuthRecord({
							...this.formData,
							projectId: this.$route.params.projectId
						})
						if (res.data.id) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.org-funds-wrapper {
	margin-bottom: 20px;
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
	.sub-title {
		padding-bottom: 20px;
		font-weight: 700;
		font-size: 16px;
	}
}
</style>
