<template>
	<Modal
		:title="title"
		:value="visible"
		width="400"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="org-funds-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="teamForm"
			style="margin-bottom: 30px"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="解除原因" prop="reason">
						<Input
							type="textarea"
							:row="3"
							v-model.trim="formData.reason"
							placeholder="请输入授权原因"
							:disabled="viewsData !== null"
						/>
					</FormItem>
				</Col>
			</Row>

			<!--			<Row :gutter="32">-->
			<!--				<Col span="24">-->
			<!--					<FormItem label="解除授权时间" prop="department">-->
			<!--						<DatePicker-->
			<!--							type="date"-->
			<!--							placeholder="请选择解除授权时间"-->
			<!--							clearable-->
			<!--							style="width: 100%"-->
			<!--							v-model="formData.arrivalTime"-->
			<!--							format="yyyy-MM-dd"-->
			<!--						></DatePicker>-->
			<!--					</FormItem>-->
			<!--				</Col>-->
			<!--			</Row>-->
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onSubmit">确定</Button>
		</div>
	</Modal>
</template>

<script>
import teamApi from "@/api/setting/team"
const { cancelAuthRecord } = teamApi

export default {
	name: "AddRelieve",
	props: {
		visible: {},
		authRecordId: {
			type: String,
			default: undefined
		},
		viewsData: {}
	},
	data() {
		return {
			loading: false,
			title: "",
			formData: {
				authRecordId: null,
				reason: null
			},
			rules: {
				authRecordId: {
					required: true,
					message: "解除授权ID不能为空",
					trigger: "blur change"
				},
				reason: {
					required: true,
					message: "解除授权原因不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.teamForm && this.$refs.teamForm.resetFields()
				if (this.viewsData !== null) {
					this.formData = {
						authRecordId: this.authRecordId,
						reason: null,
						...this.viewsData
					}
					this.title = "查看"
				} else {
					this.formData = {
						authRecordId: this.authRecordId,
						reason: null
					}
					this.title = "解除授权"
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onSubmit() {
			this.$refs.teamForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await cancelAuthRecord({
							...this.formData,
							projectId: this.$route.params.projectId
						})
						if (res.data.id) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.org-funds-wrapper {
	margin-bottom: 20px;
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
	.sub-title {
		padding-bottom: 20px;
		font-weight: 700;
		font-size: 16px;
	}
}
</style>
