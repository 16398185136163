import request from "@/utils/request"

const moduleName = "/project"

export default {
	// 获取列表
	apiGetPage: data => {
		if (!data || !data.projectId) {
			throw new Error("Invalid data provided")
		}
		return request(`${moduleName}/${data.projectId}/finances/page`, {
			params: data
		}).catch(error => {
			console.error("Error fetching page:", error)
			throw error
		})
	},

	// 新增
	apiAdd: data => {
		if (!data || !data.projectId) {
			throw new Error("Invalid data provided")
		}
		return request(`${moduleName}/${data.projectId}/finance`, {
			method: "post",
			body: data
		}).catch(error => {
			console.error("Error adding finance:", error)
			throw error
		})
	},

	// 修改
	apiUpdate: data => {
		if (!data || !data.projectId || !data.id || !data) {
			throw new Error("Invalid data provided")
		}
		return request(`${moduleName}/${data.projectId}/finance/${data.id}`, {
			method: "put",
			body: data
		}).catch(error => {
			console.error("Error updating finance:", error)
			throw error
		})
	},

	// 作废
	apiDelete: data => {
		if (!data || !data.projectId || !data.id) {
			throw new Error("Invalid data provided")
		}
		return request(`${moduleName}/${data.projectId}/finance/${data.id}`, {
			method: "delete"
		}).catch(error => {
			console.error("Error deleting finance:", error)
			throw error
		})
	},

	// 导出列表
	apiGetList: data => {
		if (!data || !data.projectId) {
			throw new Error("Invalid data provided")
		}
		return request(`${moduleName}/${data.projectId}/finances`, {
			params: data
		}).catch(error => {
			console.error("Error fetching list:", error)
			throw error
		})
	},

	// 获取发票
	apiGetInvoice: data => {
		if (!data || !data.projectId || !data.financeId) {
			throw new Error("Invalid data provided")
		}
		return request(
			`${moduleName}/${data.projectId}/finance/${data.financeId}/files`,
			{
				params: data
			}
		).catch(error => {
			console.error("Error fetching invoice:", error)
			throw error
		})
	},

	// 删除发票
	apiDeleteInvoice: data => {
		if (!data || !data.projectId || !data.financeId) {
			throw new Error("Invalid data provided")
		}
		return request(
			`${moduleName}/${data.projectId}/finance/${data.financeId}/files`,
			{
				method: "delete",
				params: data
			}
		).catch(error => {
			console.error("Error deleting invoice:", error)
			throw error
		})
	}
}
