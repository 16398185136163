import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/flowable/model"

export default {
	...commonApi(moduleName),
	addAuthRecord: data =>
		request(`/project/${data.projectId}/auth-record`, {
			method: "post",
			body: data
		}),
	cancelAuthRecord: data =>
		request(
			`/project/${data.projectId}/auth-record/${data.authRecordId}/cancel`,
			{
				method: "post",
				params: data
			}
		)
}
