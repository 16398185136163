<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<div class="content-wrap-title">
			<h3 style="height: 32px; line-height: 32px">团队授权管理</h3>
			<Button
				type="primary"
				class="ivu-btn-right"
				icon="md-add"
				style="position: absolute; top: 15px; right: 25px"
				@click="handleAddShow"
				v-if="
					!(projectActionPermissions.indexOf('btn_project_team_add') === -1)
				"
				>新增人员
			</Button>
		</div>
		<CtmsSearchBox @onSearch="initList" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="被授权人名称">
				<Input
					placeholder="请输入被授权人名称"
					v-model="searchParams.realName"
				/>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="角色">
				<Select
					v-model="searchParams.roleId"
					:transfer="true"
					placeholder="请选择角色"
					clearable
				>
					<Option
						v-for="(opt, i) in roleList"
						:key="`${opt.id}_${i}`"
						:value="opt.id"
						:label="opt.name"
					></Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="类型">
				<Select
					v-model="searchParams.type"
					clearable
					:transfer="true"
					placeholder="请选择类型"
				>
					<Option :value="1">授权</Option>
					<Option :value="2">取消授权</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="在组成员" placement="center">
				<Checkbox v-model="searchParams.inGroup"
					><span style="color: #1890ff">是否仅展示当前在组成员</span></Checkbox
				>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			:viewsData="viewsData"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
		></Add>
		<AddRelieve
			:visible="addRelieveVisible"
			:authRecordId="authRecordId"
			:viewsData="viewsData"
			@onCancel="addRelieveVisible = false"
			@onOk="handleAddRelieveOk"
		></AddRelieve>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:id="viewId"
		></ViewDetail>
	</div>
</template>

<script>
import api from "@/api/project/team"
import processApi from "@/api/setting/process"
import { mapState } from "vuex"
import ViewDetail from "@/views/Setting/Staff/ViewDetail.vue"
import Add from "./Add.vue"
import AddRelieve from "./AddRelieve.vue"

const { getTeamUsers, getAuthRecordList } = api
const { apiGetRoleList } = processApi
export default {
	name: "Team",
	data() {
		return {
			loading: false,
			projectId: this.$route.params.projectId,
			listData: [],
			roleList: [],
			searchParams: {
				realName: "",
				roleId: "",
				type: "",
				inGroup: false
			},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			addVisible: false,
			addRelieveVisible: false,
			viewsData: {},
			viewVisible: false,
			viewId: "",
			authRecordId: "",
			columns: [
				{
					title: "被授权人名称",
					key: "realName",
					minWidth: 180
				},
				{
					title: "被授权人账号",
					key: "username",
					minWidth: 180
				},
				{
					title: "授权/解除时间",
					key: "createdTime",
					minWidth: 120
				},
				{
					title: "被授权/解除角色",
					key: "roleId",
					minWidth: 120,
					render: (h, params) => h("span", {}, this.getRoleName(h, params))
				},
				{
					title: "授权人",
					key: "createdBy",
					minWidth: 120
				},
				{
					title: "授权/解除原因",
					key: "reason",
					minWidth: 120
				},
				{
					title: "类型",
					key: "type",
					minWidth: 120,
					render: (h, params) =>
						h("span", {}, params.row.type === 1 ? "授权" : "解除")
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看",
								on: {
									click: () => this.handleViewShow(params.row)
								}
							},
							{
								label: "解除",
								on: {
									click: () => this.handleAddRelieveShow(params.row)
								}
							}
						]
						if (
							!(this.projectActionPermissions.indexOf("btn_project_team_add") === -1) && params.row.type !== 1
						) {
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			]
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res1 = await apiGetRoleList()
				if (res1) {
					this.roleList = res1.data || []
				}
				this.searchParams = {
					...this.searchParams,
					projectId: this.projectId,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				}
				const res2 = await getAuthRecordList(this.searchParams)
				if (res2) {
					this.listData = res2.data
				}
				this.loading = false
			})
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				realName: "",
				roleId: "",
				type: "",
				inGroup: false
			}
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		getRoleName(h, params) {
			return this.roleList.find(item => {
				return params.row.roleId === item.id
			}).name
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 解除成功刷新列表
		handleAddRelieveOk() {
			this.addRelieveVisible = false
			this.initList()
		},
		// 新增显示弹窗
		handleAddShow(obj) {
			this.addVisible = true
			this.viewsData = null
		},
		// 解除显示弹窗
		handleAddRelieveShow(obj) {
			this.addRelieveVisible = true
			this.authRecordId = obj.id
			this.viewsData = null
		},
		// 查看
		handleViewShow(obj) {
			if (obj && obj.type === 1) {
				// 授权
				this.addVisible = true
				this.viewsData = obj
			}
			if (obj && obj.type === 2) {
				// 取消授权
				this.addRelieveVisible = true
				this.viewsData = obj
			}
			// this.viewVisible = true
			// this.viewId = obj.userId
		}
	},
	created() {
		this.initList()
		this.$asyncDo(async () => {
			this.loading = true
			const res = await getTeamUsers({
				projectId: this.projectId
			})
			if (res) {
				this.listdata = res.data
			}
		})
	},
	computed: {
		...mapState("permission", ["projectActionPermissions"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146 - this.infoHeight
			}
		})
	},
	components: {
		Add,
		AddRelieve,
		ViewDetail
	}
}
</script>

<style scoped lang="less"></style>
